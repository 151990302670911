import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  { 
    path: '', redirectTo: 'login-screen', pathMatch: 'full'
  },
  // {
  //   path: '',
    
  //   loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  // },
  {
    path: 'tab4',
    loadChildren: () => import('./tab4/tab4.module').then( m => m.Tab4PageModule)
  },
  // {
  //   path: 'tab2',
  //   loadChildren: () => import('./tab2/tab2.module').then( m => m.Tab2PageModule)
  // },
  // {
  //   path: 'tab1',
  //   loadChildren: () => import('./tab1/tab1.module').then( m => m.Tab1PageModule)
  // },
  // {
  //   path: 'tab3',
  //   loadChildren: () => import('./tab3/tab3.module').then( m => m.Tab3PageModule)
  // },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },

  {
    path:'add-school-step1',
    loadChildren:() => import('./tab2/new-school/step1/step1.module').then(m => m.Step1PageModule)
  },
 {
  path:'add-school-step2',
    loadChildren:() => import('./tab2/new-school/step2/step2.module').then(m => m.Step2PageModule)

 },
  {
    path: 'add-school-step3',
    loadChildren: () => import('./tab2/new-school/step3/step3.module').then( m => m.Step3PageModule)
  },
  {
    path: 'add-school-step4',
    loadChildren: () => import('./tab2/new-school/step4/step4.module').then( m => m.Step4PageModule)
  },
  {
    path: 'create-school-class',
    loadChildren: () => import('./tab2/new-school/step3/create-school-class/create-school-class.module').then( m => m.CreateSchoolClassPageModule)
  },
  {
    path: 'login-screen',
    loadChildren: () => import('./login-screen/login-screen.module').then( m => m.LoginScreenPageModule)
  },
  {
    path: 'school-detail',
    loadChildren: () => import('./tab2/school-detail/school-detail.module').then( m => m.SchoolDetailPageModule)
  },
  {
    path: 'chat-direct',
    loadChildren: () => import('./tab2/school-detail/chat-direct/chat-direct.module').then( m => m.ChatDirectPageModule)
  },
  {
    path: 'main-chat',
    loadChildren: () => import('./tab2/main-chat/main-chat.module').then( m => m.MainChatPageModule)
  },
  {
    path: 'main-chat-detail',
    loadChildren: () => import('./tab2/main-chat/main-chat-detail/main-chat-detail.module').then( m => m.MainChatDetailPageModule)
  },
  {
    path: 'create-account',
    loadChildren: () => import('./login-screen/create-account/create-account.module').then( m => m.CreateAccountPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'search-school',
    loadChildren: () => import('./tab2/search-school/search-school.module').then( m => m.SearchSchoolPageModule)
  },
  {
    path: 'school-results-search',
    loadChildren: () => import('./tab2/school-results-search/school-results-search.module').then( m => m.SchoolResultsSearchPageModule)
  },
  {
    path: 'confirmation-create-school-class',
    loadChildren: () => import('./tab2/new-school/step3/create-school-class/confirmation-create-school-class/confirmation-create-school-class.module').then( m => m.ConfirmationCreateSchoolClassPageModule)
  },
  

  {
    path: 'modalcities',
    loadChildren: () => import('./tab2/search-school/modalcities/modalcities.module').then( m => m.ModalcitiesPageModule)
  },
  {
    path: 'modalcountries',
    loadChildren: () => import('./tab2/search-school/modalcountries/modalcountries.module').then( m => m.ModalcountriesPageModule)
  },
  {
    path: 'modalstates',
    loadChildren: () => import('./tab2/search-school/modalstates/modalstates.module').then( m => m.ModalstatesPageModule)
  },
  {
    path: 'event-details',
    loadChildren: () => import('./tab3/event-details/event-details.module').then( m => m.EventDetailsPageModule)
  },
 

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
