import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserDataService {

public currentUserId : string = "Aucun"
public currentUserFirstName 
public currentUserLastName 
public currentUserEmail



  constructor() { }
}
